import {
	isBetween,
	isMax,
	isMaxLength,
	isMin,
	isMinLength,
	isRequired,
	isNumber,
	isRequiredNotZero
} from "@/services/rule-services";

export type RuleFunction = (value: string) => boolean | string | Object;

export type ValidationRules = {
	[key: string]: Array<RuleFunction>;
};

export type ConfigFields = {
	[key: string]: ConfigFieldEntity;
};

export interface ConfigField {
	required?: Boolean;
	required_not_zero?: Boolean;
	disabled?: Boolean;
	between?: Boolean;
	numeric?: Boolean;
	minLength?: number;
	maxLength?: number;
	min?: number;
	max?: number;
}

export class ConfigFieldEntity implements ConfigField {
	required_not_zero?: Boolean = false;
	required?: Boolean = false;
	disabled?: Boolean = false;
	between?: Boolean = false;
	numeric?: Boolean = false;
	minLength?: number | undefined;
	maxLength?: number | undefined;
	min?: number | undefined;
	max?: number | undefined;

	constructor(config?: ConfigField) {
		this.required_not_zero = config?.required_not_zero || false;
		this.required = config?.required || false;
		this.disabled = config?.disabled || false;
		this.between = config?.between || false;
		this.numeric = config?.numeric || false;
		this.minLength = config?.minLength;
		this.maxLength = config?.maxLength;
		this.min = config?.min;
		this.max = config?.max;
	}

	getLimit() {
		return this.maxLength || false;
	}
}

export class RuleBuilder {
	private rules: RuleFunction[] = [];

	constructor(private config: ConfigField) {}

	private addRule(rule: RuleFunction): void {
		this.rules.push(rule);
	}

	private addRequiredRule(): void {
		if (this.config.required) {
			this.addRule(isRequired);
		}
		if (this.config.required_not_zero) {
			this.addRule(isRequiredNotZero);
		}
	}

	private addNumericRule(): void {
		if (this.config.numeric) {
			this.addRule(isNumber);
		}
	}

	private addBetweenRule(): void {
		if (this.config.between) {
			const { min = 0, max = 0 } = this.config; // Default values if min or max are not provided
			this.addRule((value: string) => isBetween(value, { min, max }));
		}
	}

	private addLengthRules(): void {
		if (this.config.minLength !== undefined) {
			this.addRule((value: string) =>
				isMinLength(value, this.config.minLength!)
			);
		}

		if (this.config.maxLength !== undefined) {
			this.addRule((value: string) =>
				isMaxLength(value, this.config.maxLength!)
			);
		}
	}

	private addMinMaxRules(): void {
		if (this.config.min !== undefined) {
			this.addRule((value: string) => isMin(value, this.config.min!));
		}

		if (this.config.max !== undefined) {
			this.addRule((value: string) => isMax(value, this.config.max!));
		}
	}

	buildRules(): RuleFunction[] {
		this.addRequiredRule();
		this.addNumericRule();
		this.addBetweenRule();
		this.addLengthRules();
		this.addMinMaxRules();
		return this.rules;
	}
}
