import {
	ConfigFieldEntity,
	ConfigFields,
	RuleBuilder,
	ValidationRules,
} from "./RuleBuilder";

const MIN_INT = 1;
const MAX_INT = 30;

export const configFields: ConfigFields = {
	id: new ConfigFieldEntity({
		disabled: true,
		numeric: true,
	}),
	external_id: new ConfigFieldEntity({
		disabled: true,
		numeric: true,
	}),
	name: new ConfigFieldEntity({
		required: true,
		minLength: undefined,
		maxLength: 100,
	}),
	account_id: new ConfigFieldEntity(),
	advertiser_id: new ConfigFieldEntity({
		required: true,
	}),
	event_attribution_id: new ConfigFieldEntity({
		required: true,
		numeric: true,
	}),
	event_type_id: new ConfigFieldEntity(),
	segment_id: new ConfigFieldEntity(),
	value: new ConfigFieldEntity({
		required: true,
		numeric: true,
		min: 0,
	}),
	click_window_days: new ConfigFieldEntity({
		required: false,
		between: true,
		min: MIN_INT,
		max: MAX_INT,
	}),
	view_window_days: new ConfigFieldEntity({
		required: false,
		between: true,
		min: MIN_INT,
		max: MAX_INT,
	}),
	events_fired_today: new ConfigFieldEntity(),
	events_fired_yesterday: new ConfigFieldEntity(),
	events_fired_past_7_days_avg: new ConfigFieldEntity(),
	events_fired_past_30_days_look_back: new ConfigFieldEntity(),
	advertiser_name: new ConfigFieldEntity(),
	event_tag_type_id: new ConfigFieldEntity(),
	active: new ConfigFieldEntity(),
};

/**
 * Función para crear reglas de validación dinámicamente
 * @returns
 */
export function validationRules(): ValidationRules {
	const validationRules: ValidationRules = {};

	for (const fieldName in configFields) {
		if (configFields.hasOwnProperty(fieldName)) {
			const ruleBuilder = new RuleBuilder(configFields[fieldName]);
			const rules = ruleBuilder.buildRules();
			validationRules[fieldName] = rules;
		}
	}

	return validationRules;
}
