import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";

import { EventEntity } from "@/models/events/Event";
import { getError } from "@/utils/resolveObjectArray";
import { TypeLoading } from "@/interfaces/loading";
import {
	ConfigFieldEntity,
	ConfigFields,
	ValidationRules,
} from "@/models/events/RuleBuilder";
import {
	ConfigContinue,
	EVENT_FIELD,
	EventCreate,
	EventTagResponse,
	EventUpdate,
	ResourceItem,
	ResourceSegment,
} from "@/interfaces/event";
import CardTextField from "@/components/Content/CardTextField.vue";
import CardAutocomplete from "@/components/Content/CardAutocomplete.vue";
import CardSelect from "@/components/Content/CardSelect.vue";
import CardCopy from "@/components/Content/CardCopy.vue";
import CardSwitch from "@/components/Content/CardSwitch.vue";
import CardAction from "@/components/Content/CardAction.vue";
import CardButton from "@/components/Content/CardButton.vue";
import {
	DataFieldEntity,
	ResourceConfigEntity,
	resourceConfigData,
} from "@/models/events/Field";
import { SnackNotificationEntity } from "@/models/Snack";
import SnackMessage from "@/components/Commons/Loaders/SnackMessage/index.vue";
import { sleep } from "@/utils/convert";
import { CardActionType } from "@/interfaces/action";
import {
	AttributionType,
	findAttributionByType,
	isAttributionElegible,
	prepareFilteredSegments,
} from "@/models/events/Tab";
import { isNull, isNumber } from "lodash";
import ButtonTooltip from "@/components/Content/Commons/ButtonTooltip.vue";

@Component({
	components: {
		CardTextField,
		CardAutocomplete,
		CardSelect,
		CardCopy,
		CardAction,
		SnackMessage,
		CardButton,
		CardSwitch,
		ButtonTooltip,
	},
})
export default class EventForm extends Vue {
	// Declarar la propiedad 'value' utilizando @Prop
	@Prop({ required: true }) private value!: EventEntity;
	@Prop({ required: true }) private rules!: ValidationRules;
	@Prop({ required: true }) private config!: ConfigFields;

	// Propiedad para validar el formulario
	public valid: boolean = false;
	public EVENT_FIELD = EVENT_FIELD;
	public snackData = new SnackNotificationEntity();

	@Getter("events/storeDataFields") dataFields!: DataFieldEntity;

	// Método para establecer el estado de carga utilizando Vuex
	private async setLoadingData(actionType?: TypeLoading) {
		await this.dispatchStore("loading/setLoadingData", actionType);
	}

	private async dispatchEvent<T>(
		config: ConfigContinue<T>
	): Promise<EventUpdate> {
		const method = this.hasEventID ? "update" : "create";
		return await this.dispatchStore(`events/${method}`, config);
	}

	private async dispatchStore<T>(moduleFunc: string, data: T) {
		return await this.$store.dispatch(moduleFunc, data);
	}

	// Método para establecer el estado de carga utilizando Vuex
	public async fetchResource(type: EVENT_FIELD) {
		try {
			//if (!isEmpty(this.dataFields.resources[type])) return;

			let config = this.getResourceConfig(type);

			this.dataFields.loading[type] = true;

			const items = await this.$store.dispatch(
				config.dispatch,
				config.params
			);

			let resource: ResourceItem[] = [];

			if (EVENT_FIELD.SEGMENT_ID === type) {
				resource = await prepareFilteredSegments(
					items as ResourceSegment[],
					this.computedData.advertiser_id || null
				);
			} else {
				resource = items;
			}

			this.dataFields.resources[type] = resource;

			this.dataFields.loading[type] = false;

			return items;
		} catch (error) {
			this.dataFields.loading[type] = false;
			return [];
		}
	}
	
	private getResourceConfig(type: EVENT_FIELD): ResourceConfigEntity {
		return resourceConfigData[type];
	}

	public get isTagGenerating() {
		return this.dataFields.isTag("loading");
	}

	public get isTagGenerated() {
		return this.dataFields.isTag("generated");
	}

	public get isEdit() {
		return Number(this.$route.params.id) > 0;
	}

	public get hasEventID() {
		return Number(this.computedData.id) > 0;
	}

	public get isDisabledAttribution() {
		if (this.hasEventID) return this.isEnableTabElegible;
		return this.getConfig.event_attribution_id.disabled;
	}

	// Propiedad computada para procesar los datos antes de asignarlos
	public get computedData() {
		// Aquí puedes realizar cualquier procesamiento necesario
		return this.value;
	}
	public async setMaxDay(fieldName,value) {
		this.computedData[fieldName]= value;
		await this.$nextTick();
		let currentValue = Math.min(value || 0, 30);
		currentValue = Math.max(currentValue, 0);
		this.computedData[fieldName]= currentValue;		
	}
	// Propiedad computada para obtener las configuraciones {dispatch, filters, options}
	public get getConfig() {
		return this.config;
	}

	// Propiedad computada para obtener las reglas de validación
	public get getRules() {
		return this.rules;
	}

	// Propiedad computada para obtener los errores
	private get getFails() {
		return this.$store.state.proccess.errors;
	}

	public get isEnableTabElegible(): boolean {
		const eventAttribution = this.computedData?.event_attribution;
		return isAttributionElegible(eventAttribution);
	}

	// Método para manejar acciones
	public async handleAction(action: { type: string }): Promise<void> {
		switch (action.type) {
			case CardActionType.CANCEL:
				this.handleCancel();
				break;
			case CardActionType.SAVE_CONTINUE:
				await this.handleSubmit(true);
				break;
		}
	}

	private async addEventTagTypeValidation(validates?: any) {
		this.config.event_tag_type_id = new ConfigFieldEntity(validates);
		this.config.event_type_id = new ConfigFieldEntity(validates);
		this.$emit("update", {
			type: "rules",
			key: "event_tag_type_id",
			value: new ConfigFieldEntity(validates),
		});
		this.$emit("update", {
			type: "rules",
			key: "event_type_id",
			value: new ConfigFieldEntity(validates),
		});
		return await sleep(150);
	}

	// Validar el formulario
	private async validate(): Promise<boolean> {
		// Utilizar una afirmación de tipo para indicar a TypeScript que $refs.form tiene un método 'validate'
		const formComponent: any = this.$refs.form;

		if (formComponent && typeof formComponent.validate === "function") {
			return await formComponent.validate();
		}

		return false;
	}
	tooltipData(params: any) {
		return {
			icon: "mdi-information-outline",
			text: params,
			to: "",
			right: params?.right ?? true,
		};
	}

	/**
	 * SUBMIT: create/update event
	 * @returns
	 */
	private async submitEvent(params: {
		continue: Boolean;
		redirect: Boolean;
	}): Promise<EventUpdate> {
		/**
		 * Prepara el payload
		 */
		const payload: EventCreate | EventUpdate = this.hasEventID
			? this.value.getUpdatePayload()
			: this.value.getCreatePayload();

		/**
		 * Envío de datos al Modulo Event
		 */
		return await this.dispatchEvent({
			redirect: params.redirect,
			continue: params.continue,
			data: payload,
		});
	}

	/**
	 * SUBMIT: generate tag
	 * @returns
	 */
	private async submitGenerateTag() {
		this.dataFields.seTagData("loading", true);
		this.dataFields.seTagData("generated", "");

		/**
		 * Prepara el payload
		 */
		const tagPayload = this.value.geyEventTagPayload();

		/**
		 * Dispatch: generateTag
		 */
		const response: EventTagResponse = await this.dispatchStore(
			`events/generateTag`,
			{
				event_id: tagPayload.id,
				event_tag_type_id: tagPayload.event_tag_type_id,
			}
		);

		this.dataFields.seTagData("generated", response.tag);
		this.dataFields.seTagData("loading", false);

		return response;
	}

	// Método para generar tag
	public async handleGenerateTag(): Promise<void> {
		try {
			await this.addEventTagTypeValidation({
				required: true,
				numeric: true,
			});
			/**
			 * Validar el formulario antes de continuar
			 */
			if (!(await this.validate())) return;

			/**
			 * Establecer el estado de carga antes de realizar la acción
			 */
			await this.setLoadingData(TypeLoading.loading);

			/**
			 * create/update event
			 */
			const responseEvent = await this.submitEvent({
				continue: true,
				redirect: false,
			});

			this.$emit("update", {
				type: "field",
				key: "event",
				value: responseEvent,
			});

			await sleep(50);

			await this.fireEventAttributionId(true);

			/**
			 * Restablecer el estado de carga después de realizar la acción
			 */
			await this.setLoadingData();

			/**
			 * Generate Tag
			 */
			await this.submitGenerateTag();
		} catch (error) {
			this.dataFields.seTagData("generated", "");
			this.dataFields.seTagData("loading", false);
			this.dataFields.tag.loading = false;

			// Restablecer el estado de carga en caso de error
			await this.setLoadingData();
		}
	}

	// Manejar la presentación del formulario
	public async handleSubmit(isContinue: Boolean = false) {
		try {
			await this.addEventTagTypeValidation({
				required: false,
				numeric: true,
			});

			/**
			 * Validar el formulario antes de continuar
			 */
			if (!(await this.validate())) return;

			/**
			 * Establecer el estado de carga antes de realizar la acción
			 */
			await this.setLoadingData(TypeLoading.loading);

			/**
			 * create/update event
			 */
			const responseEvent = await this.submitEvent({
				continue: isContinue,
				redirect: this.dataFields.isOnlyOnjects(
					this.computedData.event_attribution_id
				),
			});

			this.$emit("update", {
				type: "field",
				key: "event",
				value: responseEvent,
			});

			await sleep(50);

			await this.fireEventAttributionId(true);

			/**
			 * Restablecer el estado de carga después de realizar la acción
			 */
			await this.setLoadingData();
		} catch (error) {
			// Manejar errores durante el envío del formulario
			console.error("handleSubmit", { error: error });

			// Restablecer el estado de carga en caso de error
			await this.setLoadingData();
		}
	}

	// Manejar la cancelación del formulario
	private handleCancel() {
		// Redirigir a la página al listado de eventos
		this.$router.push({ name: "EventIndex" });
	}

	// Propiedad computada para obtener un error específico
	public getFail(index: any) {
		return getError(this.getFails, index);
	}

	// Vue lifecycle hooks
	private created() {
		// Your code here
		// For example, you can perform some setup logic
	}

	private mounted() {
		// Use $nextTick to ensure the component is fully rendered
		this.$nextTick(async () => {
			// For example, you can access the DOM or make additional setup
			this.fetchResource(EVENT_FIELD.EVENT_ATTRIBUTION_ID).then(
				async () => {
					if (this.isEdit) {
						await this.fireEventAttributionId(false);
					} else {
						await this.setDefaultData();
					}
				}
			);

			if (this.isEdit) {
				try {
					this.fetchResource(EVENT_FIELD.ADVERTISER_ID);
					this.fetchResource(EVENT_FIELD.EVENT_TYPE_ID);
					this.fetchResource(EVENT_FIELD.SEGMENT_ID);
					this.fetchResource(EVENT_FIELD.EVENT_TAG_TYPE_ID);
				} catch (error) {
					console.error("mounted", { error });
				}
			}
		});
	}

	private findAttribution(type: AttributionType) {
		const items = this.dataFields.resources.event_attribution_id;
		return findAttributionByType(items, type);
	}

	/**
	 * Setear datos por defecto
	 */
	private async setDefaultData() {
		// obtener attribution ALL
		const ATTRIBUTION = this.findAttribution(AttributionType.ALL_TEXT);
		// Se asigna ALL por defecto: event_attribution_id
		this.computedData.event_attribution_id = ATTRIBUTION?.id;
	}

	private async fireEventAttributionId(reFetch: Boolean = false) {
		this.$emit("change", {
			key: EVENT_FIELD.EVENT_ATTRIBUTION_ID,
			value: this.dataFields.resources.event_attribution_id,
			selected: this.computedData.event_attribution_id,
			fetch: reFetch,
		});
		if (reFetch) {
			// restaurar validacion de tag type
			await this.addEventTagTypeValidation({
				required: false,
				numeric: true,
			});
			await this.validate();
		}
	}

	@Watch("computedData.event_attribution_id")
	private onEventAttributionId(selected: number | null): void {
		this.fireEventAttributionId(false);
	}

	@Watch("computedData.advertiser_id")
	private onEventAdvertiserId(val: number | null, old: number | null): void {
		if (!isNull(old)) {
			this.fetchResource(EVENT_FIELD.SEGMENT_ID);
			this.computedData.segment_id = null;
		}
	}

	// @Watch("computedData.segment_id")
	// private onEventSegmentId(val: number | null): void {
	// 	this.fetchResource(EVENT_FIELD.ADVERTISER_ID);
	// }
}
