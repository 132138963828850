import { Component, Vue } from "vue-property-decorator";

import CreateTabs from "@/views/Admin/Commons/Tabs.vue";
import FormEvent from "@/views/Admin/Event/Dependencies/Form/index.vue";
import Alertize from "@/components/Alertize.vue";
import { EventEntity } from "@/models/events/Event";
import { configFields, validationRules } from "@/models/events/Validate";
import { ConfigFields, ValidationRules } from "@/models/events/RuleBuilder";
import { TabItemEntity } from "@/models/Core/Tab";
import {
	NAVIGATION_METHOD_PUSH,
	NAVIGATION_METHOD_REPLACE,
	getTabItems,
} from "@/models/events/Tab";
import { ResourceItem } from "@/interfaces/event";
import { DestinationRoute } from "@/interfaces/route";

@Component({
	components: {
		CreateTabs,
		FormEvent,
		Alertize,
	},
})
export class EventMixin extends Vue {
	public valid: boolean = false;
	public eventData: EventEntity = new EventEntity();
	public redirectTo: string = "";
	public currentTab: number = 0;
	public items: TabItemEntity[] = getTabItems(false);
	public rules: ValidationRules = validationRules();
	public configFields: ConfigFields = configFields;

	// Declarar explícitamente los métodos del mixin.
	public async handleTabIndex(
		tabIndex: number,
		eventId?: number | null
	): Promise<void> {
		try {
			this.currentTab = tabIndex;

			/**
			 * Se prepara la ruta de destino
			 */
			const destinationRoute: DestinationRoute = {
				name: "EventEdit",
				params: { id: String(eventId || this.eventData.id) },
				query: { tab: String(tabIndex) },
			};

			/**
			 * Se comprueba si la ruta actual es igual a la ruta de destino
			 */
			const isCurrentSameRoute = this.isSameRoute(destinationRoute);

			/**
			 * Si la ruta de destino = actual el metodo es replace o sino push
			 */
			const navigationMethod = isCurrentSameRoute
				? NAVIGATION_METHOD_REPLACE
				: NAVIGATION_METHOD_PUSH;

			this.$router[navigationMethod](destinationRoute).catch((error) => {
				this.handleError(error);
			});
		} catch (error) {
			this.handleError(error);
		}
	}

	private handleError(error: any) {
		if (error instanceof Error) {
			this.handleNavigationError(error);
		} else {
			console.error("Excepción desconocida en la navegación:", error);
		}
	}

	private isSameRoute(destinationRoute: DestinationRoute) {
		// Compara el nombre de la ruta
		const isSameName = this.$route.name === destinationRoute.name;

		// Compara los parámetros
		const isSameParams =
			JSON.stringify(this.$route.params) ===
			JSON.stringify(destinationRoute.params);

		// Compara la cadena de consulta
		const isSameQuery =
			JSON.stringify(this.$route.query) ===
			JSON.stringify(destinationRoute.query);

		return isSameName && isSameParams && isSameQuery;
	}

	private handleNavigationError(error: Error) {
		if (error.name !== "NavigationDuplicated") {
			// Manejar otros tipos de errores
			console.error("Error en la navegación:", error);
		} else {
			console.warn(
				"NavigationDuplicated: Redundant navigation detected."
			);
		}
	}

	public currentTabSelected(key: number) {
		return this.currentTab === key;
	}

	public onEventDataChange!: (newVal: string, oldVal: string) => void;

	public handleUpdate(params: { type: string; key: string; value: any }) {
		switch (params.type) {
			case "rules":
				this.configFields[params.key] = params.value;
				this.rules = validationRules();
				break;
			case "field":
				this.eventData.id = params.value.id;
				this.eventData.external_id = params.value.external_id;
				break;
		}
	}

	// Método para observacion de eventos
	public handleChange<T>(params: {
		key: string;
		value: ResourceItem[];
		selected: number | null;
	}): void {}

	// Vue lifecycle hooks
	private created() {}

	private mounted() {
		this.$nextTick(() => {});
	}
}
